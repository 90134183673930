
import * as bootstrap from 'bootstrap'
import Utils from '../../../../../utils'
import DefaultController from "../../../../defaultController";
import UserEntity from "../../../../user/entity";
import TeamsSettingsUserOverviewShowVacationDayAllowance from "./show/vacation_day_allowances";
import TeamsSettingsUserOverviewShowTimeEntryProfileMaps from "./show/time_entry_profiles";

export default class TeamsSettingsUserOverviewShow extends DefaultController {
    private editModal: any;
    async init() {
        this.entity = "users";
        this.editModal = new bootstrap.Modal((document.querySelector("#editUser") as HTMLElement));
        await super.init();
        this.childs = {
            vacation_day_allowance: new TeamsSettingsUserOverviewShowVacationDayAllowance(this),
            time_entry_profile: new TeamsSettingsUserOverviewShowTimeEntryProfileMaps(this)
        }
    }

    protected getEntityData(elem: any) {
        return UserEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editUserForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editUserForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    this.editModal.hide();
                    this.toastr.success(`${Utils.translate('user.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                }
            }
        })
    }
}